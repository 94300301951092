import { useCallback, useEffect, useRef, useState } from 'react';

type StatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped'
  | 'paused';

type RecorderErrors =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error';

const useMediaRecorder = (): {
  blob?: Blob;
  error?: RecorderErrors | string;
  start: (constraints: MediaStreamConstraints) => Promise<void>;
  status?: StatusMessages;
  stop: () => void;
  url?: string;
} => {
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [blob, setBlob] = useState<Blob | undefined>(undefined);
  const [url, setMediaBlobUrl] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<StatusMessages>('idle');
  const [error, setError] = useState<RecorderErrors | string | undefined>();

  const getMediaStream = useCallback(
    async (constraints: MediaStreamConstraints) => {
      setStatus('acquiring_media');

      try {
        // const { audio, ...others } = constraints;
        mediaStream.current = await window.navigator.mediaDevices.getUserMedia(
          constraints,
        );

        // audioStream
        //   .getAudioTracks()
        //   .forEach((audioTrack) => stream.addTrack(audioTrack));
        // mediaStream.current = stream;

        if (constraints.video) {
          const video = document.querySelector(
            'video#video--preview',
          ) as HTMLVideoElement;
          if (video) {
            video.srcObject = mediaStream.current;
            video.autoplay = true;
            video.muted = true;
            video.playsInline = true;
            video.onloadedmetadata = function () {
              video.play();
            };
            video.play();
          }
        }

        setStatus('idle');
      } catch (e: any) {
        setError(e.name);
        setStatus('idle');
      }
    },
    [],
  );

  useEffect(() => {
    if (!window.MediaRecorder) {
      setError("Le navigateur n'est pas supporté");
    } else if (!window.navigator.mediaDevices.getUserMedia) {
      setError("Ce navigateur ne prend pas en charge l'enregistrement audio");
    }

    return () => {
      if (mediaStream.current) {
        const tracks = mediaStream.current.getTracks();
        tracks.forEach(track => track.clone().stop());
      }
    };
  }, [getMediaStream]);

  const start = async (constraints: MediaStreamConstraints) => {
    setError(undefined);
    if (!mediaStream.current) {
      await getMediaStream(constraints);
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some(track => 'ended' === track.readyState);

      if (isStreamEnded) {
        await getMediaStream(constraints);
      }

      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStream.current.active) {
        return;
      }
      mediaRecorder.current = new MediaRecorder(mediaStream.current);
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      // mediaRecorder.current.onstart = onRecordingStart;
      mediaRecorder.current.onerror = e => {
        // console.error('onerror', e.error.message);
        console.error('onerror', 'il y a une erreur');
        setError('recorder_error');
        setStatus('idle');
      };
      mediaRecorder.current.start();
      setStatus('recording');
    }
  };

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
  };

  // const onRecordingStart = () => {};

  const onRecordingStop = () => {
    if (mediaRecorder.current) {
      const { mimeType } = mediaRecorder.current;

      const newBlob = new Blob(mediaChunks.current, { type: mimeType });
      const blobUrl = URL.createObjectURL(newBlob);
      setStatus('stopped');
      setBlob(newBlob);
      setMediaBlobUrl(blobUrl);
      mediaRecorder.current = null;
    }
  };

  const stop = () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('stopping');
        mediaRecorder.current.stop();
        if (mediaStream.current) {
          mediaStream.current.getTracks().forEach(track => track.stop());
          mediaStream.current = null;
        }
        mediaChunks.current = [];
      }
    }
  };

  return { blob, error, start, status, stop, url };
};

export default useMediaRecorder;
