import { ActionProps } from '~/components/Actions/props';
import IconLink from '~/icons/Link';
import IconMessageCaptions from '~/icons/MessageCaptions';
import IconNote from '~/icons/Note';

const useAdminNotes = ({
  pathname,
}: {
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/admin/notes${to}` === pathname ? 'is-active' : undefined;
    }

    return pathname.slice(0, `/admin/notes${to}`.length) === `/admin/notes${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconNote,
      label: 'Notes',
      to: `/admin/notes`,
    },
    {
      className: isActive(`/extracts`),
      icon: IconLink,
      label: 'URLs Extraites',
      to: `/admin/notes/extracts`,
    },
    {
      className: isActive(`/notebook`),
      icon: IconMessageCaptions,
      label: 'Carnet de notes',
      to: `/admin/notes/notebook`,
    },
  ];
};

export default useAdminNotes;
