import dayjs from 'dayjs';
import { MediaData, ThoughtData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import { DocumentType, MediaType } from 'submodules/innedit-type';

import Button from '~/components/Button';
import IconCamera from '~/icons/Camera';

const Camera: FC<{ espaceId?: string }> = function ({ espaceId }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const accept = '.pdf,audio/*,video/*,image/*';

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.currentTarget && e.currentTarget.files) {
      onDrop(e.currentTarget.files);
    }
  };

  const handleOpenBrowser = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onDrop = async (files: FileList) => {
    if (files.length > 0) {
      const model = new ThoughtData({
        espaceId,
        orderDirection: 'desc',
      });
      const date = dayjs().format('YYYY-MM-DD');
      const thought = await model.create({
        date,
      });

      const promises: Promise<DocumentType<MediaType>>[] = [];
      // const tmpPreviews: any[] = [];
      for (let i = 0; i < files.length; i += 1) {
        const media = files.item(i);
        if (media) {
          // const preview = URL.createObjectURL(media);
          // tmpPreviews.push(preview);

          const mediaData = new MediaData({
            espaceId,
            parentCollectionName: 'thoughts',
            parentId: thought.id,
          });

          promises.push(mediaData.uploadFile(media));
        }
      }
      // setPreviews(tmpPreviews);
      toast.promise(Promise.all(promises), {
        error: 'Problème lors du téléchargement des médias',
        pending: 'Téléchargement des médias en cours',
        success: 'Le téléchargment des médias est une réussite',
      });
    }
  };

  return (
    <div>
      <input
        ref={inputRef}
        accept={accept}
        className="hidden"
        multiple
        onChange={handleOnChange}
        type="file"
      />
      <Button
        className="h-[36px] w-[36px] text-neutral-400 md:h-[32px] md:w-[32px]"
        iconClassName="h-[24px] md:h-[20px]"
        iconLeft={IconCamera}
        onClick={handleOpenBrowser}
        variant="clear"
      />
    </div>
  );
};

export default Camera;
