import styled from '@emotion/styled';
import React, { FC, SyntheticEvent, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Audio from '~/components/Notebook/Audio';
import Camera from '~/components/Notebook/Camera';
import IconArrowUp from '~/icons/ArrowUp';
import createThought from '~/utils/createThought';

const Textarea = styled.textarea`
  resize: none;
  outline: none;
  line-height: 24px;
  display: flex;
`.withComponent(TextareaAutosize);

const NotebookForm: FC<{ espaceId?: string }> = function ({ espaceId }) {
  const [text, setText] = useState<string>('');

  const handleOnChange = (event: SyntheticEvent<any>) => {
    const { value } = event.currentTarget;

    setText(value);
  };

  const handleOnCreate = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    event.preventDefault();

    if (text && text.trim()) {
      try {
        setText('');
        await toast.promise(createThought({ espaceId, text }), {
          error: "Une erreur c'est produit lors de la création",
          pending: 'En cours de création de la pensée',
          success: 'La création de la pensée a réussi',
        });
      } catch (error) {
        toast.error((error as Error).message);
      }
    }
  };

  return (
    <div className="-pl-1.5 sticky bottom-0 flex items-end space-x-2 bg-background pb-6 pr-2 pt-2 md:pb-3">
      <Camera espaceId={espaceId} />
      <div className="flex flex-1 items-end rounded-[18px] border bg-light-100 pl-[12px]">
        <Textarea
          className="my-[6px] flex-1 items-center rounded-r-none bg-transparent md:my-[3px]"
          maxRows={text ? undefined : 1}
          onChange={handleOnChange}
          placeholder="Message"
          value={text}
        />
        <div>
          {text ? (
            <Button
              className="m-[4px] flex h-[28px] w-[28px] rounded-full border-none md:h-[22px] md:min-h-[22px] md:w-[22px] md:min-w-[22px]"
              color="neutral"
              iconClassName="h-[14px] w-[14px]"
              iconLeft={IconArrowUp}
              onClick={handleOnCreate}
              size="xs"
              square
              title="Enregistrer un message"
            />
          ) : (
            <Audio espaceId={espaceId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotebookForm;
