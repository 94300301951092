import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconMicrophone: FC<IconType> = ({
  bgColor = 'transparent',
  className,
  color = 'currentColor',
  onClick,
}) => (
  <svg
    className={className}
    height="100%"
    onClick={onClick}
    viewBox="0 0 384 512"
    width="100%"
  >
    <path
      d="M182.588,383.833L176,384C105.3,384 48,326.7 48,256L48,216C48,202.7 37.3,192 24,192C10.7,192 0,202.7 0,216L0,256C0,345.1 66.2,418.7 152,430.4L152,464L104,464C90.7,464 80,474.7 80,488C80,501.3 90.7,512 104,512L248,512L250.461,511.877C262.596,510.652 272,500.469 272,488C272,474.7 261.3,464 248,464L200,464L200,430.4C285.8,418.7 352,345.1 352,256L352,216C352,202.7 341.3,192 328,192C314.7,192 304,202.7 304,216L304,256C304,324.487 250.231,380.4 182.588,383.833Z"
      fill={color}
    />
    <path
      d="M180.938,0.125L176,0C123,0 80,43 80,96L80,256C80,309 123,352 176,352L180.938,351.875C231.65,349.298 272,307.341 272,256L272,96C272,44.659 231.65,2.702 180.938,0.125Z"
      fill={bgColor}
    />
    <path
      d="M180.938,0.125L176,0C123,0 80,43 80,96L80,256C80,309 123,352 176,352L180.938,351.875C231.65,349.298 272,307.341 272,256L272,96C272,44.659 231.65,2.702 180.938,0.125ZM224,96L224,256C224,282.5 202.5,304 176,304C149.5,304 128,282.5 128,256L128,96C128,69.5 149.5,48 176,48C202.5,48 224,69.5 224,96Z"
      fill={color}
    />
  </svg>
);

export default IconMicrophone;
