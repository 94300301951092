import dayjs from 'dayjs';
import { MediaData, ThoughtData } from 'packages/innedit';
import React, { FC, useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import IconCircleStop from '~/icons/CircleStop';
import IconMicrophone from '~/icons/Microphone';
import useMediaRecorder from '~/utils/useMediaRecorder';

const Audio: FC<{ espaceId?: string }> = function ({ espaceId }) {
  const { blob, status, start, stop } = useMediaRecorder();

  useEffect(() => {
    if ('stopped' === status && blob) {
      const model = new ThoughtData({
        espaceId,
        orderDirection: 'desc',
      });
      const date = dayjs().format('YYYY-MM-DD');
      model
        .create({
          date,
        })
        .then(thought => {
          const mediaData = new MediaData({
            espaceId,
            parentCollectionName: 'thoughts',
            parentId: thought.id,
          });

          return toast.promise(mediaData.uploadBlob(blob, 'Audio'), {
            error: "Problème lors du téléchargement de l'audio",
            pending: "Téléchargement de l'audio en cours",
            success: "Le téléchargement de l'audio est une réussite",
          });
        })
        .then(media => console.info(media))
        .catch(error => toast.error(error.message));
    }
  }, [blob, status]);

  const handleStartAudioRecording = () => {
    start({ audio: true });
  };

  const handleStopRecording = async () => {
    stop();
  };

  return (
    <Button
      className="m-[4px] flex h-[28px] w-[28px] rounded-full border-none bg-transparent p-0 text-neutral-400 md:h-[22px] md:w-[22px]"
      color="neutral"
      iconClassName="h-[18px] w-[18px] md:h-[16px] md:w-[16px]"
      iconLeft={'recording' === status ? IconCircleStop : IconMicrophone}
      onClick={
        'recording' === status ? handleStopRecording : handleStartAudioRecording
      }
      title="Enregistrer un audio"
      variant="link"
    />
  );
};

export default Audio;
